.rc-tree li span.rc-tree-iconEle {
	margin-right: 12px;
	margin-top: -2px;
	vertical-align: text-top;
}

.rc-tree li ul {
	margin: 4px 0;
}

html,
body {
	height: 100%;
	margin: 0px;
	padding: 0px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

a {
	outline: none;
	text-decoration: none;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ccc;
}

